var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{ref:"user-table",attrs:{"columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
      enabled: false,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true,
      selectAllByGroup: true,
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'code')?_c('span',[_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(props.row.code)+" ")])],1):(props.column.field == 'action')?_c('span',[_c('feather-icon',{staticClass:"text-body ml-2",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteItem(props.row.id)}}})],1):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.pageChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
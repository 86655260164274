<template>
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      cancel-title="Hủy bỏ"
      centered
      size="xl"
      hide-footer
    >
      <my-tabs
        :listTabs="listTabs"
        defaultActive="activeTab"
        typeCss="active-full"
        @setActive="setActive"
      >
      </my-tabs>
      <info
        v-if="isRendered('info')"
        ref="info"
        :dataInput="detailData"
        class="tab-pane"
        :class="{'active':activeTab==='info'}"
      />
      <list-equip
        v-if="isRendered('listEquip')"
        class="tab-pane"
        :superviseId="superviseId"
        :class="{'active':activeTab==='listEquip'}"
      />
      <list-business
        v-if="isRendered('business')"
        class="tab-pane"
        :superviseId="superviseId"
        :class="{'active':activeTab==='business'}"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import MyTabs from '@/components/tabs/MyTabs.vue'
import Info from './supervise/Info.vue'
import ListEquip from './supervise/ListSuper.vue'
import listBusiness from './supervise/ListBusiness.vue'

export default {
  components: {
    MyTabs,
    Info,
    ListEquip,
    listBusiness,
  },
  props: {
    detailData: {
      type: Object,
      default: _ => {},
    },
    id: {
      type: String,
      default: '',
    },
    superviseId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeTab: null,
      detailId: '',
      listTabs: [
        {
          key: 'info',
          title: 'Thông tin',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'listEquip',
          title: 'Đoàn thanh tra',
          icon: 'UsersIcon',
          isRendered: false,
        },
        {
          key: 'business',
          title: 'Doanh nghiệp',
          icon: 'BriefcaseIcon',
          isRendered: false,
        },
      ],
      dataInput: {
        code: '',
        dateTime: '',
        description: '',
        fileName: null,
        modelFormData: null,
        bussinessId: null,
      },
      userData: null,
    }
  },

  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },

  created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'info'
      this.listTabs[0].isRendered = true
    }
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },
  methods: {
    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    setActive(activeTab) {
      const active = this.activeTab
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      const indexTabNotActive = this.listTabs.findIndex(x => x.key === active)
      if (active !== this.activeTab) {
        if (tab) {
          tab.isRendered = true
          this.listTabs[indexTabNotActive].isRendered = false
        }
      }
    },
  },
}
</script>

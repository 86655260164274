<template>
  <div>
    <vue-good-table
      ref="user-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: false,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'code'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>
        <!-- Chức năng -->
        <span v-else-if="props.column.field == 'action'">
          <feather-icon
            icon="Trash2Icon"
            size="18"
            class="text-body ml-2"
            @click="deleteItem(props.row.id)"
          />

        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

// import EditEquip from './components/EditEquip.vue'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    BBadge,
    // EditEquip,
  },
  props: {
    superviseId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      totalRecord: 10,
      isActive: false,
      dataList: [],
      columns: [
        {
          label: 'TÊN THÀNH VIÊN',
          field: 'name',
          sortable: false,
        },
        {
          label: 'GIỚI TÍNH',
          field: 'genderString',
          sortable: false,
        },
        {
          label: 'CHỨC VỤ',
          field: 'position',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        superviseId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {

    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      this.urlQuery.superviseId = this.superviseId
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_MISSION_INSPECTION, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },
    // Hiển thị modal thêm
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalIdCreate)
    },
    // search(val) {
    //   if (val != null) {
    //     this.urlQuery.declareEquipmentId = this.$route.params.id
    //     this.urlQuery.pageNumber = 1
    //     this.urlQuery.key = val
    //     this.fetchData(this.urlQuery)
    //   } else {
    //     this.urlQuery.declareEquipmentId = this.$route.params.id
    //     this.urlQuery.pageNumber = 1
    //     this.urlQuery.key = ''
    //     this.fetchData(this.urlQuery)
    //   }
    // },
  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
